




















































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Team extends Vue {
  tab = null;
  dialog = false;

  selectedMember = {
    img: "",
    title: "",
    fullName: "",
    bio: ""
  };

  memberListX = [
    {
      img: require("@/assets/tulin-pinar-eser.png"),
      title: "Mütevelli Heyeti Başkanı",
      fullName: "Dr. Tülin Pınar Demirbaş",
      bio: `2001 Eskişehir Osmangazi Üniversitesi Tıp Fakültesinden mezun olmuştur. Halen İskenderun’da İskenderun Devlet Hastanesi’nde görev yapmaktadır. Evli ve 2 çocuk sahibi olan Dr. Tülin Pınar Demirbaş 2017 şubat ayından beri KAHEV Yönetim Kurulundadır.`
    },
    {
      img: require("@/assets/emine-cigdem-catal.png"),
      title: "Mütevelli Heyeti Başkan Yardımcısı",
      fullName: "Uzm. Dr. Emine Çiğdem Çatal",
      bio: `Ankara Üniversitesi Tıp Fakültesi’nden Ocak 1996’da mezun oldu. 2007 yılında Anatomi Uzmanı oldu. 2009-2017 tarihleri arasında devlet hizmet yükümlülüğü ile birlikte Mersin Devlet Hastanesi’nde çalıştı. 2017 yılından beri Mersin Şehir Eğitim ve Araştırma Hastanesi’nde çalışmaktadır. Sağlık Bakanlığı hastanelerinde adli tabiplik birimlerinde ve idari birimlerde çalışmıştır. Aynı zamanda 2009 yılından beri profesyonel Organ ve Nakli Koordinatörlüğü yapmaktadır. 1 kız, 1 erkek çocuk annesidir. 2017 yılından beri KAHEV yönetim kurulunda aktif olarak çalışıyor.`
    },
    {
      img: require("@/assets/burcu-akdag-ozkok.png"),
      title: "Mütevelli Heyeti Üyesi",
      fullName: "Op. Dr. Burcu Akdağ Özkök",
      bio: `2010 yılında Gazi Üniversitesi Tıp Fakültesinden mezun oldu. 2011- 2016 yılları arasında Ankara Zekai Tahir Burak Kadın Hastalıkları Eğitim ve Araştırma Hastanesi’nde Kadın Hastalıkları ve Doğum uzmanlığı ihtisası yaptı. 2016 -2018 yıllarında Devlet Hizmet Yükümlüsü olarak Ağrı Devlet Hastanesi’nde çalıştı. Şu anda İstanbul’da özel bir klinikte çalışmakta. Evli ve 1 kız çocuk annesi olan Burcu Akdağ Özkök Ağustos 2017 tarihinden beri KAHEV yönetim ekibinde bulunmaktadır.`
    },
    {
      img: require("@/assets/canan-topcu.png"),
      title: "Mütevelli Heyeti Üyesi",
      fullName: "Uzm. Dr. Canan Topcu",
      bio: `İstanbul Tıp Fakültesi’nden 2002 yılında mezun oldu. 2004-2009 yıllarında Göztepe Eğitim Araştırma Hastanesi’nde ihtisas yaptı. 2005 yılında doğan dünya tatlısı Alkım Tunay ile 1 kız annesi oldu. 2009-2010 yılında Batman Kozluk Devlet Hastanesi’nde devlet yükümlülük hizmetini yaptı. 2011 yılından itibaren İstanbul’da özel bir hastanede Anestezi ve Reanimasyon Uzman doktor olarak çalışıyor. 2017 yılından beri KAHEV yönetim kurulunda aktif olarak çalışıyor.`
    },
    {
      img: require("@/assets/demet-baser.png"),
      title: "Mütevelli Heyeti Üyesi",
      fullName: "Uzm. Dr. Demet Başer",
      bio: `Ege Üniversitesi Tıp Fakültesi’nden 2005 yılında mezun oldu. 2006-2011 yılları arasında Akdeniz Üniversitesi Pediatri bölümünde ihtisas yaptı. 2011 yılında mecburi hizmet için Batman Kadın Doğum ve Çocuk Hastanesi’ne atandı. 2021 yılında geçtiği Batman Eğitim ve Araştırma Hastanesi’nde halen görev yapmaktadır. 2011 yılında evlendi, 2013 ve 2015 yıllarında Arya ve Merve adında iki kız çocuk dünyaya getirdi.2017 yılından beri KAHEV yönetim kurulunda aktif olarak yer alan Dr. Başer, aynı zamanda 6 yıldır Pediatri Uzmanlık Akademisi Derneği’nin yönetim kurulunda çalışmaktadır.`
    }
  ];

  memberListY = [
    {
      img: require("@/assets/demet-baser.png"),
      title: "Yönetim Kurulu Başkanı",
      fullName: "Dr. Demet Başer",
      bio: `Ege Üniversitesi Tıp Fakültesi’nden 2005 yılında mezun oldu. 2006-2011 yılları arasında Akdeniz Üniversitesi Pediatri bölümünde ihtisas yaptı. 2011 yılında mecburi hizmet için Batman Kadın Doğum ve Çocuk Hastanesi’ne atandı. 2021 yılında geçtiği Batman Eğitim ve Araştırma Hastanesi’nde halen görev yapmaktadır. 2011 yılında evlendi, 2013 ve 2015 yıllarında Arya ve Merve adında iki kız çocuk dünyaya getirdi.2017 yılından beri KAHEV yönetim kurulunda aktif olarak yer alan Dr. Başer, aynı zamanda 6 yıldır Pediatri Uzmanlık Akademisi Derneği’nin yönetim kurulunda çalışmaktadır.`
    },
    {
      img: require("@/assets/gulser-goktolga-pinar.png"),
      title: "Yönetim Kurulu Başkan Yardımcısı",
      fullName: "Dr. Gülser Göktolga Pınar",
      bio: `2005 yılında Cumhuriyet Üniversitesinden mezun oldu. 2007-2008 yılları arası Elazığ Harput Devlet Hastanesi Acil serviste pratisyen hekim olarak çalıştı. 2012 yılında Fırat üniversitesi Tıp Fakültesinde ihtisasını tamamlayıp, Kadın Hastalıkları ve Doğum uzmanı oldu. 2013-2018 arası Elazığ Eğitim Araştırma Hastanesinde çalıştı. Halen Antalya Özel Anadolu Hastanesinde çalışmaktadır. Evli ve iki çocuk annesidir. 2017 yılından itibaren KAHEV’de aktif görev almaktadır. 2018 tarihinden itibaren KAHEV yönetim ekibinde bulunmaktadır.`
    },
    {
      img: require("@/assets/ayca-tuzcu.png"),
      title: "Yönetim Kurulu Üyesi",
      fullName: "Dr. Ayça Tuzcu",
      bio: `Ege Üniversitesi Tıp Fakültesi’nden 2007 yılında mezun oldu. Tıbbi Biyokimya ihtisasını İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi’nde tamamladıktan sonra Malatya ve Balıkesir illerinde görev yaptı. Halen Aydın Adnan Menderes Üniversitesi Tıp Fakültesi Tıbbi Biyokimya Anabilim Dalı’nda öğretim üyesi olarak çalışmaktadır. Başhekim yardımcılığı ve Tıp Eğitimi Koordinasyon Kurul üyeliği görevlerini devam ettirmektedir. Müzikseverdir, müziğin tedavi edici gücünü anlatan tıp ve müzik dersleri vermektedir. Evli ve 2 kız çocuk annesidir.`
    },
    {
      img: require("@/assets/betul-topaloglu.png"),
      title: "Yönetim Kurulu Üyesi",
      fullName: "Dr. Betül Topaloğlu",
      bio: `1994 yılında Çukurova Üniversitesi Tıp Fakültesi’nden mezun oldu. 1994-1998 yılları arasında Aksaray Laleli Sağlık Ocağı’nda, 1998 -2009 yılları arasında Aksaray Kadın Doğum ve Çocuk Hastanesi’nde çalıştı. 2009 yılından bu yana Mersin Çukurova 75. Yıl Aile Sağlığı Merkezinde Aile Hekimi olarak görev yapmaktadır. 2000 doğumlu Belin Gül adında güzel bir genç kız annesidir. 2017’den beri KAHEV’de aktif olarak görev almaktadır.`
    },
    {
      img: require("@/assets/melahat-akgun.png"),
      title: "Yönetim Kurulu Üyesi",
      fullName: "Dr. Melahat Akgün",
      bio: `1979’da Azerbaycan’da doğdu. Azerbaycan Tıp Üniversitesi’nde Pediatri ihtisası yaptı. 2005 yılına kadar Bakü 7 Nolu Çocuk Enf. Y. B. Ü.’de doktorluk yaptı. 2005 yılında evlenip Türkiye’ye geldi. 2008 yılında Türk vatandaşlığını alınca İstanbul 112’de göreve başladı. 2019 nisan itibarı ile Eyüp Sakarya ASM’de aile hekimi olarak görev yapmaktadır. Evli ve bir çocuk annesidir. 2019 yılından itibaren KAHEV yönetim ekibinde yer almaktadır.`
    },
    {
      img: require("@/assets/ozge-balcin.png"),
      title: "Yönetim Kurulu Üyesi",
      fullName: "Dr. Özge Balçın",
      bio: `1984’ de Sivas ili Yıldızeli ilçesinde dünyaya geldi. İlk, orta ve lise eğitimini Sivas’ta tamamladı. Eskişehir Osmangazi Üniversitesi Tıp Fakültesi’nden 2008 yılında mezun oldu. 2010 yılında evlendi. 2008-2017 yılları arasında çeşitli illerde ve hastanelerde acil hekimliği yaptı. 2017 yılından itibaren Eskişehir’de işyeri hekimliği yapmaktadır. Aynı zamanda ressam ve çocuk kitapları yazarıdır.`
    },
    {
      img: require("@/assets/suna-dundar.png"),
      title: "Yönetim Kurulu Üyesi",
      fullName: "Dr. Suna Dündar",
      bio: `1972 yılında Amasya'da doğdu. İlk, orta ve lise eğitimini Amasya'da tamamladı. 28.07.1995 İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesinden mezun oldu. 2003 Aralık ayında SSK Okmeydanı Hastanesi'nde Biyokimya Ve Klinik Biyokimya ihtisasını tamamladı. Mart 2005 tarihinde Sosyal Güvenlik Kurumunda çalışmaya başladı. Halen Samsun SGK Biyokimya Uzmanı olarak görev yapmaktadır. Evli ve iki çocuk annesidir. Öykü, roman, senaryo yazarlığı ile ilgilenmektedir. Eserleri: Yara 1915 (Roman),  Umudun Rengi (Roman), Aslında Şahit Çoktu (Hikaye), Elli'nin Kiri (Editör-Yazar).`
    },
    {
      img: require("@/assets/sulan-tuncel.png"),
      title: "Yönetim Kurulu Üyesi",
      fullName: "Dt. Şulan Tuncel",
      bio: `1963 yılında İstanbul’da dünyaya geldi. Orta ve lise eğitimini Ataköy Lisesi’nde tamamlayarak 1986 yılında İstanbul Üniversitesi Diş Hekimliği Fakültesinden mezun oldu. 1987 yılında evlenerek serbest diş hekimliği yaptı. 1992 yılında dünyaya getirdiği oğlunun eğitimi için 2000 yılında İzmir‘e taşındı ve 2001/2021 yılları arasında kurum hekimliği yaptı. Aynı zamanda, 2013 yılından beri özel muayenehanesinde serbest diş hekimi olarak mesleğini icra etmektedir.2018 yılında evlenen oğlundan Hayat Defne isminde bir kız torun sahibidir. İzmir Vakıf İl grubunda 2018 yılından beri aktif KAHEV gönüllüsüdür.`
    },
    {
      img: require("@/assets/tulin-pinar-eser.png"),
      title: "Yönetim Kurulu Üyesi",
      fullName: "Dr. Tülin Pınar Demirbaş",
      bio: `2001 Eskişehir Osmangazi Üniversitesi Tıp Fakültesinden mezun olmuştur. Halen Eskişehir de Aile Hekimi olarak görev yapmaktadır. Evli ve 2 çocuk sahibi olan Dr. Tülin Pınar Demirbaş 2017 şubat ayından beri KAHEV Yönetim Kurulundadır.`
    }
  ];

  memberListZ = [
    {
      img: require("@/assets/rahime-kacmaz.png"),
      title: "",
      fullName: "Dr. Rahime Kaçmaz",
      bio: `Osmangazi Üniversitesi Tıp Fakültesi’nden 1999’da mezun oldu. 1999-2013 yıllarında Sağlık Bakanlığı Taşra ve Merkez teşkilatlarında çeşitli İdari Görevlerde bulundu. 2013 yılından beri Sağlık Bakanlığı Halk Sağlığı Genel Müdürlüğü’nde Birim Sorumlusu olarak görev yapmaktadır. 1 çocuk sahibi olup 2017 yılından beri KAHEV’de görev almaktadır.`
    },
    {
      img: require("@/assets/canan-topcu.png"),
      title: "",
      fullName: "Dr. Canan Topcu",
      bio: `1979 yılında İstanbul’da doğan Uzm. Dr. Canan TOPÇU, İlköğretimini Pendik Merkez İlkokulunda tamamladıktan sonra lise öğrenimini Marmara Kolejinde tamamlamış akabinde İstanbul Üniversitesi Tıp Fakültesinden 2002 yılında mezun olmuştur. 2009 yılında Göztepe Eğitim ve Araştırma Hastanesinde Anestezi ve Reanimasyon alanında ihtisasını tamamladıktan sonra, 2009-2010 yılları arasında Batman Kozluk Devlet Hastanesinde anestezi ve reanimasyon alanında Uzm. Hekim olarak görev yapmıştır. Daha sonra, 2011-2018 yılları arasında Özel Avicenna Ataşehir Hastanesinde, 2018-2019 yılları arasında Özel NP Beyin Hastanesinde, 2020-2022 yılları arasında da Özel Avicenna Umut Hastanesinde uzman anestezi hekimi olarak görev yapan Uzm. Dr. Canan TOPÇU,  Marmara Üniversitesi Pendik Eğitim ve Araştırma Hastanesini, anestezi ve reanimasyon alanında uzman hekim olarak görevine devam etmektedir.  Bununla birlikte, 2018 yılında Türkiye’de kadın hekimlerin bir araya gelerek kurduğu Kadın Hekimler Eğitime Destek Vakfının (KAHEV) kurucusu, mütevelli heyeti, 2018-2022 tarihleri arasında yönetim kurulu başkan yardımcısı olarak çalıştı. 2022’den itibaren denetim kurulu üyesi olarak çalışmaktadır. Bunlara ek olarak, Türk Yoğun Bakım Derneği (TYBD) ve Türk Anestezi ve Reanimasyon Derneği (TARD) üyesi olan Uzm. Dr. Canan TOPÇU, ilk yardım eğitici eğitmenliği ve işyeri hekimliği sertifikalarına sahiptir.`
    },
    {
      img: require("@/assets/yasemin-yumusakhuylu.png"),
      title: "",
      fullName: "Dr. Yasemin Yumuşakhuylu",
      bio: `2003 yılında Marmara Üniversitesi Tıp Fakültesi’nden mezun oldu. Uzmanlık eğitimini Sağlık Bakanlığı Göztepe Eğitim ve Araştırma Hastanesinde Fiziksel Tıp ve Rehabilitasyon alanında 2012 yılında tamamladı. Zorunlu hizmetini aynı hastanede tamamlayıp uzman doktor olarak çalışmaya devam etti. Kasım 2018 tarihinde Medeniyet Üniversitesi Tıp Fakültesinde öğretim üyesi olarak çalışmaya başlamış, görevine aynı yerde devam etmektedir. Evli ve 2 çocuk annesidir.`
    },
    {
      img: require("@/assets/alev-saylan.png"),
      title: "",
      fullName: "Dr. Alev Şaylan",
      bio: `Akdeniz Üniversitesi Tıp Fakültesi’nden 1998 yılında mezun oldu. 2007-2011 yılları arasında Hacettepe Üniversitesi Tıp Fakültesi Anesteziyoloji ve Reanimasyon Anabilim Dalı’nda uzmanlık eğitimini tamamladı. Uzman olarak Ankara Türkiye Yüksek İhtisas Eğitim ve Araştırma Hastanesi, İstanbul Kartal Dr. Lütfi Kırdar Eğitim ve Araştırma Hastanesi’nde çalıştı. Halen Ankara Dışkapı Yıldırım Beyazıt Eğitim ve Araştırma Hastanesi Anesteziyoloji ve Reanimasyon Kliniği’nde çalışmaya devam etmektedir. Kuruluş sürecinden itibaren KAHEV bünyesinde çeşitli çalışmalarda bulunmuş, KAHEV dışında da pek çok farklı sosyal sorumluluk projesinde aktif olarak çalışmış ve çalışmaya devam etmektedir. 1 kız çocuk annesidir.`
    }
  ];

  memberListZ201923 = [
    {
      img: require("@/assets/no-photo.png"),
      title: "Op. Dr.",
      fullName: "Betül Erdemir",
      bio: `1998 yılında Selçuk Üniversitesi Tıp Fakültesi’nden mezun oldu. 1999-2005 yılları arasında Kartal Dr.Lütfü Kırdar Eğitim ve Araştırma Hastanesinde Plastik ve Rekonstruktif Cerrahi uzmanlığı ihtisasını tamamladı. Karaman Devlet Hastanesi,  Validebağ Öğretmenler Hastanesi ve Pendik Devlet Hastanesi’nde çalıştıktan sonra devlet görevinden ayrılıp  2011-2012 arası 1(bir) yıl süreyle  Özel Emsey Hastanesi’nde çalıştı. Şu anda İstanbul’da kendi kliniğinde Plastik Rekonstruktif ve Estetik Cerrah olarak çalışmaktadır. Evli ve iki çocuk annesidir. 2017 yılından beri KAHEV oluşumu içinde olup 2019 Şubat ayından beri denetim kurulu üyesi olarak çalışmalara katılmaktadır.`
    },
    {
      img: require("@/assets/betul-topaloglu.png"),
      title: "Dr.",
      fullName: "Betül Topaloğlu",
      bio: `1994 yılında Çukurova Üniversitesi Tıp Fakültesi’nden mezun oldu. 1994-1998 yılları arasında Aksaray Laleli Sağlık Ocağı’nda ,1998 -2009 yılları arasında Aksaray Kadın Doğum ve Çocuk Hastanesi’nde çalıştı. 2009 yılından bu yana Mersin Çukurova 75.yıl Aile Sağlığı Merkezinde Aile Hekimi olarak görev yapmaktadır. 2000 doğumlu Belin Gül adında güzel bir genç kız annesidir. 2017 den beri KAHEV de aktif olarak görev almaktadır.`
    },
    {
      img: require("@/assets/rahime-kacmaz.png"),
      title: "Dr.",
      fullName: "Rahime Kaçmaz",
      bio: `Osmangazi Üniversitesi Tıp Fakültesi’nden 1999 da mezun oldu. 1999-2013 yıllarında Sağlık Bakanlığı Taşra ve Merkez teşkilatlarında çeşitli İdari Görevlerde bulundu. 2013 yılından beri Sağlık Bakanlığı Halk Sağlığı Genel Müdürlüğü’nde Birim Sorumlusu olarak görev yapmaktadır. 1 çocuk sahibi olup 2017 yılından beri KAHEV’de görev almaktadır.`
    },
    {
      img: require("@/assets/no-photo.png"),
      title: "Dr.",
      fullName: "Yasemin Yumuşakhuylu",
      bio: `2003 yılında Marmara Üniversitesi Tıp Fakültesi’nden mezun oldu. Uzmanlık eğitimini Sağlık Bakanlığı Göztepe Eğitim ve Araştırma Hastanesinde Fiziksel Tıp ve Rehabilitasyon alanında 2012 yılında tamamladı. Zorunlu hizmetini aynı hastanede tamamlayıp uzman doktor olarak çalışmaya devam etti. Kasım 2018 tarihinde Medeniyet Üniversitesi Tıp Fakültesinde öğretim üyesi olarak çalışmaya başlamış, görevine aynı yerde devam etmektedir. Evli ve 2 çocuk annesidir.`
    }
  ];

  memberListY201923 = [
    {
      img: require("@/assets/bilge-can-hasde.png"),
      title: "Dr.",
      fullName: "Bilge Can Hasde",
      bio: `2006 yılında İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi’nden mezun oldu.
Uzmanlık eğitimini 2007-2012 yılları arasında Gazi Üniversitesi Tıp Fakültesi Çocuk Cerrahisi Ana Bilim Dalı’nda tamamladı.
2013-2014 yılları arasında Şanlıurfa Balıklıgöl Devlet Hastanesi ve Şanlıurfa Mehmet Akif İnan Eğitim ve Araştırma Hastanesi’de;
2014-2017 yılları arasında Antakya Devlet Hastanesi’de Çocuk Cerrahisi uzmanı olarak görev yaptı.
2017 yılından bu yana Yıldırım Beyazıt Üniversitesi Yenimahalle Eğitim ve Araştırma Hastanesi’nde görev yapıyor.
Evli ve 2 çocuk annesi.

2018 tarihinden itibaren KAHEV yönetim ekibinde bulunmaktadır.`
    },
    {
      img: require("@/assets/gokce-haspolat.png"),
      title: "Dr.",
      fullName: "Gökçe Haspolat",
      bio: `2005 yılında Celal Bayar Üniversitesi Tıp Fakültesi’den mezun oldu.
2005 yılında kısa bir süre hekimlik yaptıktan sonra önce Abbott İlaç Firmasında Medikal Müdür sonra Novartis İlaç Firmasında Kıdemli Medikal Müdür olarak 2012 yılına kadar yöneticilik yaptı.
2008 yılında doğan oğlu Ege için 2012 yılında tekrar hekimliğe döndü ve o zamandan beri Medikal Estetik Hekim olarak çalışıyor.

KAHEV Yönetim Kurulunda 2018 başından bari aktif görev yapmaktadır.`
    },
    {
      img: require("@/assets/gulser-goktolga-pinar.png"),
      title: "Dr.",
      fullName: "Gülser Göktolga Pınar",
      bio: `2005 yılında Cumhuriyet Üniversitesinden mezun oldu.
2007-2008 yılları arası Elazığ Harput Devlet Hastanesi Acil serviste pratisyen hekim olarak çalıştı.
2012 yılında Fırat üniversitesi Tıp Fakültesinde ihtisasını tamamlayıp, Kadın Hastalıkları ve Doğum uzmanı oldu.
2013-2018 arası Elazığ Eğitim Araştırma Hastanesinde çalıştı.
Halen Antalya Özel Anadolu Hastanesinde çalışmaktadır.
Evli ve iki çocuk annesidir.
2017 yılından itibaren KAHEV’de aktif görev almaktadır.
2018 tarihinden itibaren KAHEV yönetim ekibinde bulunmaktadır.`
    },
    {
      img: require("@/assets/burcu-akdag-ozkok.png"),
      title: "Op. Dr.",
      fullName: "Burcu Akdağ Özkök",
      bio: `2010 yılında Gazi Üniversitesi Tıp Fakültesinden mezun oldu. 2011- 2016 yılları arasında Ankara Zekai Tahir Burak Kadın Hastalıkları Eğitim ve Araştırma Hastanesi’nde Kadın Hastalıkları ve Doğum uzmanlığı ihtisası yaptı. 2016 -2018 yıllarında Devlet Hizmet Yükümlüsü olarak Ağrı Devlet Hastanesi’nde çalıştı. Şu anda İstanbul’da özel bir klinikte çalışmakta. Evli ve 1 kız çocuk annesi olan Burcu Akdağ Özkök Ağustos 2017 tarihinden beri KAHEV yönetim ekibinde bulunmaktadır.`
    },
    {
      img: require("@/assets/canan-topcu.png"),
      title: "Uzm. Dr.",
      fullName: "Canan Topcu",
      bio: `İstanbul Tıp Fakültesi’nden 2002 yılında mezun oldu. 2004-2009 yıllarında Göztepe Eğitim Araştırma Hastanesi’nde ihtisas yaptı. 2005 yılında doğan dünya tatlısı Alkım Tunay ile 1 kız annesi oldu. 2009-2010 yılında Batman Kozluk Devlet Hastanesi’nde devlet yükümlülük hizmetini yaptı. 2011 yılından itibaren İstanbul’da özel bir hastanede Anestezi ve Reanimasyon Uzman doktor olarak çalışıyor. 2017 yılından beri KAHEV yönetim kurulunda aktif olarak çalışıyor.`
    },
    {
      img: require("@/assets/demet-baser.png"),
      title: "Uzm. Dr.",
      fullName: "Demet Başer",
      bio: `Ege Üniversitesi Tıp Fakültesi’nden 2005 yılında mezun oldu. 2006-2011 yılları arasında Akdeniz Üniversitesi Pediatri bölümünde ihtisas yaptı. 2011 yılında mecburi hizmet için atandığı Batman Kadın Doğum ve Çocuk Hastalıkları Hastanesi’nde halen görev yapmaktadır. 2011 yılında evlendi, 2013 ve 2015 yıllarında Arya ve Merve adında iki kız çocuk dünyaya getirdi.2017 yılından beri KAHEV yönetim kurulunda aktif olarak yer alan Dr. Başer, aynı zamanda 6 yıldır Pediatri Uzmanlık Akademisi Derneği’nin yönetim kurulunda çalışmaktadır.`
    },
    {
      img: require("@/assets/emine-cigdem-catal.png"),
      title: "Uzm. Dr.",
      fullName: "Emine Çiğdem Çatal",
      bio: `Ankara Üniversitesi Tıp Fakültesi’nden Ocak 1996’da mezun oldu. 2007 yılında Anatomi Uzmanı oldu. 2009-2017 tarihleri arasında devlet hizmet yükümlülüğü ile birlikte Mersin Devlet Hastanesi’nde çalıştı. 2017 yılından beri Mersin Şehir Eğitim ve Araştırma Hastanesi’nde çalışmaktadır. Sağlık Bakanlığı hastanelerinde adli tabiplik birimlerinde ve idari birimlerde çalışmıştır. Aynı zamanda 2009 yılından beri profesyonel Organ ve Nakli Koordinatörlüğü yapmaktadır. 1 kız, 1 erkek çocuk annesidir. 2017 yılından beri KAHEV yönetim kurulunda aktif olarak çalışıyor.`
    },
    {
      img: require("@/assets/melahat-akgun.png"),
      title: "Dr.",
      fullName: "Melahat Akgün",
      bio: `1979’da Azerbaycan’da doğdu. Azerbaycan Tıp Üniversitesi’nde Pediatri ihtisası yaptı.
2005 yılına kadar Bakü 7 Nolu Çocuk Enf. Y. B. Ü.’de doktorluk yaptı. 2005 yılında evlenip Türkiye’ye geldi. 2008 yılında Türk vatandaşlığını alınca İstanbul 112’de göreve başladı. 2019 nisan itibarı ile Eyüp Sakarya ASM’de aile hekimi olarak görev yapmaktadır.
Evli ve bir çocuk annesidir.
2019 yılından itibaren KAHEV yönetim ekibinde yer almaktadır.`
    },
    {
      img: require("@/assets/tulin-pinar-eser.png"),
      title: "Dr.",
      fullName: "Tülin Pınar Demirbaş",
      bio: `2001 Eskişehir Osmangazi Üniversitesi Tıp Fakültesinden mezun olmuştur. Halen Eskişehir de Aile Hekimi olarak görev yapmaktadır. Evli ve 2 çocuk sahibi olan Dr. Tülin Pınar Demirbaş 2017 şubat ayından beri KAHEV Yönetim Kurulundadır.`
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showBio(member: any) {
    this.selectedMember = member;
    this.dialog = true;
  }
}
